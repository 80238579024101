.countdown {
  display: inline-flex;
  margin-bottom: 24px;

  .bloc-times {
    margin: 0 4px 0 0 !important;
    text-align: center;
    position: relative;
    display: flex;

    .count-title {
      position: absolute;
      bottom: -24px;
      left: 0px;
      right: 0px;
    }
  }

  .figures {
    position: relative;
    float: left;
    height: 34px !important;
    @media only screen and (max-width: 1350px) and (min-width: 1200px) {
      width: 23px !important;
    }
    @media only screen and (max-width: 1450px) and (min-width: 1350px) {
      width: 27px !important;
    }
    width: 30px !important;
    margin: 0px 2px;
    background-color: #fff;
    border-radius: 4px;

    > span {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      font-size: 28px !important;
      line-height: 32px !important;
      font-weight: 700;
      color: #232323;
    }

    .tops,
    .bottoms-back {
      border-top-left-radius: 32px !important;
      border-top-right-radius: 32px !important;
      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        line-height: 44px;
      }
    }

    .tops {
      z-index: 3;
      background-color: #f7f7f7;
      transform-origin: 50% 100%;
      -webkit-transform-origin: 50% 100%;
    }

    .bottoms {
      z-index: 1;
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: rgba(0, 0, 0, 0.02);
      }
    }

    .bottoms-back {
      z-index: 2;
      top: 0;
      height: 50%;
      overflow: hidden;
      background-color: #f7f7f7;

      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .tops,
    .tops-back {
      height: 50%;
      overflow: hidden;
      border-bottom-left-radius: 32px !important;
      border-bottom-right-radius: 32px !important;
    }

    .tops-back {
      z-index: 4;
      bottom: 0;
      background-color: #fff;
      -webkit-transform-origin: 50% 0;
      transform-origin: 50% 0;

      span {
        position: absolute;
        top: -100%;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .bloc-times {
      margin: 0 4px;
      min-width: 64px;
    }

    .figures {
      height: 32px;
      width: 28px;
      > span {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
}
